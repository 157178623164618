import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import whoami from "./whoamiReducer";
import conversation from "./conversationReducer";
import notification from "./notificationReducer";

export default history =>
	combineReducers({
		router: connectRouter(history),
		whoami,
		conversation,
		notification
	});
