import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
    //width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
});

class CustomizedInputBase extends React.Component {
  render() {
    const {
      classes,
      onSubmit,
      inputProps,
      SubmitIcon,
      background,
      onInput,
      value
    } = this.props;

    const input = (
      <Paper className={classes.root} style={{ background }}>
        {/*<IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton>*/}

        <InputBase
          className={classes.input}
          inputProps={{
            onInput
          }}
          value={value}
          {...inputProps}
        />
        {/*<IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>*/}

        {!SubmitIcon ? null : (
          <React.Fragment>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={onSubmit}
              disabled={!value.trim()}
              color={"primary"}
              className={classes.iconButton}
              aria-label="directions"
            >
              <SubmitIcon />
            </IconButton>
          </React.Fragment>
        )}
      </Paper>
    );

    if (onSubmit) {
      return <form onSubmit={onSubmit}>{input}</form>;
    } else {
      return input;
    }
  }
}

export default withStyles(styles, { withTheme: true })(CustomizedInputBase);
