import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";


import { Input } from "components";
import { Inbox, ConversationList, AppToolbar } from "containers";



const styles = theme => ({
  root: {
    background: "#f6f6f6",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",

    display: "grid",
    gridTemplateColumns: "minmax(250px, 1fr) 4fr",
    gridTemplateRows: "min-content 1fr",
    height: "100%",
    "--highlight": theme.palette.primary.main,
    "--dull": "rgba(0,0,0,.4)",
    "--green": "#6CC264"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: 36
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2, 0, 1),
    ...theme.mixins.toolbar
  },
  searchInp: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  sidebar: {
    background: "white"
  }
});

class AppLayout extends React.Component {
  state = {
    open: true
  };

  render() {
    const { classes } = this.props;

    //const open = true;

    /*const handleDrawerOpen = () => {
      this.setState({ open: true });
    };

    const handleDrawerClose = () => {
      this.setState({ open: false });
    };*/

  

    return (
      <div className={classes.root} id="chat-AppLayout">
        {/*<CssBaseline />*/}

        <div className={classes.sidebar}>
          <div className={classes.toolbar}>
            <IconButton/* onClick={handleDrawerClose}*/>
              <MenuIcon />
            </IconButton>
            {/*}  <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>*/}

            <div className={classes.searchInp}>
              <Input
                inputProps={{
                  placeholder: "Search"
                }}
                background="#F1F1F1"
                // value={this.state.message}
                // onSubmit={this.submit}
                // onInput={this.setMsg}
              />
            </div>
          </div>
          <Divider />
        </div>

        <AppBar
          color="secondary"
          position="static"
          className={clsx(
            classes.appBar
          )}
        >
          <AppToolbar/>
        </AppBar>

        <div
          className={classes.sidebar + " hover-scroll"}
          style={{
            overflowY: "auto",
            background: "white",
            "--scrollbg": "rgba(0,0,0,.1)"
          }}
        >
          <ConversationList />
        </div>
        <div
          id="chat-body-container"
          className="hover-scroll"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto"
          }}
        >
       
          <Inbox />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppLayout);
