/*.then(querySnapshot => {
        this.setState({
          conversations: querySnapshot.docs.map(this.conversationToState)
        });
      });;*/

const currentIdFromUrl=window.location.pathname.match(/\/chat\/(.+)/)

const defaultState = {
  current: currentIdFromUrl?currentIdFromUrl[1]:false,
  list: [],
  conversations: {}
};

const conversation = (state = defaultState, action) => {
  switch (action.type) {
    case "CONVERSATION_OPEN":
      return {
        ...state,
        current: action.id
      };
    case "CONVERSATION_LIST_RECEIVED":
      return {
        ...state,
        list: action.list
      };
    /*case "CONVERSATION_UPSERTED":
      return {
        ...state,
        list: [
          action.conversation,
          ...(action.id?state.list.filter(c => c.id !== action.id):state.list)
        ]
      };
*/
    case "CONVERSATION_RECEIVED":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.id]: action.history
        }
      };

    case "CONVERSATION_APPEND":
      
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.id]: [...(state.conversations[action.id]||[]), action.message]
        }
      };

    default:
      return state;
  }
};

export default conversation;
