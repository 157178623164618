module.exports = {
	PORTAL_COLLECTION:"portal",
	MESSAGE_COLLECTION: "message",
	CONVERSATION_COLLECTION: "conversation",
	CONTACT_COLLECTION: "contact",
	USER_COLLECTION: "user",
	EVENSES_MESSAGEBIRD_PHONE_NR: "3197010240707",
	MIN_SMS_LENGTH: 2,
	MAX_SMS_LENGTH: 1377
};
