import firebase from 'firebase/app';
//import 'firebase/auth';
import 'firebase/firestore';
firebase.initializeApp({ 
  apiKey: "AIzaSyDSkFfMOxvaxMrXbgGtRpXHGRSIsVUDRmo",
    authDomain: "evenses-api.firebaseapp.com",
    databaseURL: "https://evenses-api.firebaseio.com",
    projectId: "evenses-api",
    storageBucket: "evenses-api.appspot.com",
    messagingSenderId: "414920755074",
    appId: "1:414920755074:web:c1b545cb3ab796c484b19b"
 });

export default firebase

export const db = firebase.firestore();
