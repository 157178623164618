import React from "react";
import {connect} from "react-redux";

import {AppLayout} from "components";
import Toast from "containers/Toast";

import { ThemeProvider } from "@material-ui/styles";
import theme from "theme";

class App extends React.Component {

	componentDidMount(){


		let evoProps, evoUid, evoPortal

		if(window.settings){
			const {uid,portal,phone,email,...props}=window.settings.whoami
			if(uid){
				evoProps={
					email:[email],
					phone:[phone],
					...props
				}
				evoPortal=portal
				evoUid=uid
			}
		}
		if(!evoUid){
			evoUid=0
			evoProps={
				email:[],
				phone:[],
				name:"Test User",
				meta:{}
			}	
			evoPortal="dev"
		}



this.props.dispatch({
			type:"PORTAL_FETCH",
			evoPortal,
						evoUid,
			evoProps,
		})


		/*this.props.dispatch({
			type:"WHOAMI",
			evoUid,
			evoProps,
			 evoPortal
		})*/
	}


	render() {
				const {id}=this.props.whoami


				if(!id){
					return null;
				}
		return (
			<ThemeProvider theme={theme}>
				<Toast/>
				<AppLayout />
			</ThemeProvider>
		);
	}
}

export default connect(({ whoami }) => ({
  whoami
}))(App);
