import React from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import BookmarkIcon from "@material-ui/icons/BookmarkBorder";
import { List, ListItemAvatar, Avatar, Typography } from "@material-ui/core";

import moment from "momentLocale";

import randomMC from "random-material-color";

const styles = theme => ({
  inherit: {
    color: "inherit"
  },
  inline: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  head: {
    fontWeight: 500,
    flex: 1
  },
  caption: {
    marginLeft: theme.spacing(1.5)
  },
  highlight: {
    color: "var(--highlight)"
  },
  dull: {
    color: "var(--dull)"
  }
});

const StyledListItem = withStyles(theme => ({
  root: {
    "&$selected": {
      "--highlight": "white",
      "--dull": "rgba(255,255,255,.8)",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        background: theme.palette.primary.main
      }
    }
  },
  selected: {}
}))(ListItem);

class ConversationList extends React.Component {
  componentDidMount() {
    const { id } = this.props.whoami;
    this.props.dispatch({ type: "CONVERSATION_LIST_FETCH", userId: id });
  }

  setActive = id => {
    this.props.dispatch({ type: "CONVERSATION_OPEN", id });
  };

  conversationExcerpt = last => {
    const { id } = this.props.whoami;
    if (!last) {
      return "";
    }
    if (last.author.id === id) {
      return (
        <React.Fragment>
          <span className={this.props.classes.highlight}>{"You: "}</span>
          {last.content}
        </React.Fragment>
      );
    }
    return last.content;
  };

  conversationTitle = membersSnapshot => {
    const { id } = this.props.whoami;

    const everyone = membersSnapshot || [];
    const others = everyone.filter(member => member.id !== id);
    if (!others.length) {
      if (everyone.length) {
        return {
          title: "Saved messages",
          icon: <BookmarkIcon />,
          bg: "#65AADD"
        };
      }
      return {
        title: "---",
        icon: "?",
        bg: "orange"
      };
    }

    const othersNames = others
      .map(author => {
        if (!author) {
          return "---";
        }
        if (author.name) {
          return author.name;
        }
        if (author.email && author.email.length) {
          return author.email[0];
        }
        if (author.mobile && author.mobile.length) {
          return author.mobile[0];
        }
        if (author.landline && author.landline.length) {
          return author.landline[0];
        }
        return author.id;
      })
      .map(s => s.trim())
      .filter(s => s);
    const title = othersNames.join(" + ");
    return {
      title,
      icon: othersNames
        .map(s => s[0])
        .join("")
        .toUpperCase(),
      bg: randomMC.getColor({ shades: ["400"], text: title })
    };
  };

  render() {
    const { classes, list, current } = this.props;
    return (
      <List>
        {list.map(
          ({ id, members, last, updated, membersSnapshot }) => {
            const { title, icon, bg } = this.conversationTitle(membersSnapshot);
            return (
              <StyledListItem
                alignItems="flex-start"
                button
                key={id}
                selected={current === id}
                onClick={() => this.setActive(id)}
              >
                <ListItemAvatar>
                  <Avatar style={{ background: bg }}>{icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div style={{ display: "flex" }}>
                      <Typography
                        className={classes.inline + " " + classes.head}
                        component="span"
                        variant="body2"
                      >
                        {title}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.caption + " " + classes.dull}
                      >
                        {moment(updated?updated.toDate():null).format("LT")}
                      </Typography>
                    </div>
                  }
                  primaryTypographyProps={{ className: classes.inherit }}
                  secondaryTypographyProps={{
                    className: classes.inherit + " " + classes.inline
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.dull}
                      >
                        {this.conversationExcerpt(last) || "\u00A0"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </StyledListItem>
            );
          }

          /*<ListItem color="primary" >
                <ListItemIcon>
                  {id ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={id} />
              </ListItem>*/
        )}
      </List>
    );
  }
}

export default connect(({ conversation, whoami }) => ({
  list: conversation.list,
  current: conversation.current,
  whoami
}))(withStyles(styles)(ConversationList));
