import React from "react";
import { connect } from "react-redux";

import { Toolbar, Typography, Button } from "@material-ui/core";

class AppToolbar extends React.Component {
	labelMap = [
		{
			key: "booking",
			color: "primary",
			prefix: "B",
			href: val => "/admin/bookings/" + val
		},
		{
			key: "customer",
			prefix: "K",
			href: val => "/admin/customers/edit/" + val
		}
	];

	render() {
		const { conversation } = this.props;
		const { current, list } = conversation;

		const currentConversation = current
			? list.find(obj => obj.id === current)
			: false;
		const { labels } = (currentConversation||{});

		return (
			<Toolbar>
				<Typography variant="h6" noWrap>
					{"Conversations"}
				</Typography>
				<div style={{flex:1}}/>
				{!labels
					? null
					: this.labelMap.map(({ key, color, prefix, href }) => {
							return (labels[key] || []).map((val, i) => (
							
									<Button
									style={{marginLeft:10}}
									variant="contained"
									href={href(val)}
									target="_blank"
									rel="noopener noreferrer"
										color={color}
										key={key + i}
										
									>
									{prefix + val}
									</Button>
							));
					  })}
			</Toolbar>
		);
	}
}

export default connect(({ conversation }) => ({
	conversation
}))(AppToolbar);
