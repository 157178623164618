import Messagebird from "messagebird";

import {
	EVENSES_MESSAGEBIRD_PHONE_NR,
	MIN_SMS_LENGTH,
	MAX_SMS_LENGTH
} from "Constants";

const messagebirdHandle = new Messagebird("BBTLCHBtaZtzL4KheycI3JwNw");

export const lookup = nr =>
	new Promise((y, n) => {
		messagebirdHandle.lookup.read(nr, function(err, response) {
			if (err || !response.phoneNumber) {
				return y(false)
				//return n(err);
			}
			y(response.phoneNumber);
		});
	});

export const notify = ({ originator, body, recipients, reference }) => {
	if (!recipients) {
		console.warn("No recipients specified");
		return false;
	}

	body = (body || "").trim();

	if (body.length < MIN_SMS_LENGTH) {
		console.warn("Minimum length of sms is " + MIN_SMS_LENGTH);
		return false;
	}
	if (body.length > MAX_SMS_LENGTH) {
		alert("Maximum length of sms is " + MAX_SMS_LENGTH);
		return false;
	}

	messagebirdHandle.messages.create(
		{
			originator: originator || EVENSES_MESSAGEBIRD_PHONE_NR,
			recipients: recipients,
			body,
			reference
		},
		function(err, response) {
			if (err) {
				console.log("ERROR:");
				console.log(err);
			} else {
				console.log("SUCCESS:");
				console.log(response);
			}
		}
	);
};
