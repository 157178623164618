import {
  put,
  takeEvery,
  all,
  select,
  take,
  call,
  cancelled,
  delay
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase, { db } from "backend";
import { notify, lookup } from "notifications";

import {
  PORTAL_COLLECTION,
  CONVERSATION_COLLECTION,
  MESSAGE_COLLECTION,
  CONTACT_COLLECTION,
  USER_COLLECTION
} from "Constants";

function* fetchPortal({ evoPortal, evoUid, evoProps }) {
  let portal 
  let tries=3

do{
  console.log("try")
  portal = yield db
    .collection(PORTAL_COLLECTION)
    .where("key", "==", evoPortal)
    .get()
    .then(querySnapshot => {
      if (querySnapshot.docs.length) {
        return db.collection(PORTAL_COLLECTION).doc(querySnapshot.docs[0].id);
        //return portalToState(querySnapshot.docs[0]);
      }
      return false;
    });
}while(!portal && --tries>0 && (yield delay(500)))
 /* if (!portal) {
    portal = yield db.collection(PORTAL_COLLECTION).add({
      key: evoPortal
    });
    // .then(docRef => docRef.get())
    // .then(doc => portalToState(doc));
  }*/

  if (portal) {
    yield put({ type: "PORTAL_RECEIVED", portal });
    yield put({
      type: "WHOAMI",
      evoUid,
      evoProps
    });
  }
}

const whoamiToState = doc => ({
  id: doc.id,
  ...doc.data()
});

function* fetchWhoami({ evoUid, evoProps }) {
  const portal = yield take("PORTAL");

  let whoami = yield portal
    .collection(USER_COLLECTION)
    .where("evoUid", "==", evoUid)
    .get()
    .then(querySnapshot => {
      if (querySnapshot.docs.length) {
        return whoamiToState(querySnapshot.docs[0]);
      }

      return false;
    });

  if (!whoami) {
    whoami = yield portal
      .collection(USER_COLLECTION)
      .add({
        evoUid,
        ...evoProps
      })
      .then(docRef => docRef.get())
      .then(doc => whoamiToState(doc));
  }
  if (whoami) {
    yield put({ type: "WHOAMI_RECEIVED", user: whoami });
  }
}

const conversationToState = doc => ({
  id: doc.id,
  ...doc.data()
});

function* fetchConversationList({ limit = 100, userId }) {
  const portal = yield take("PORTAL");

  const list = portal
    .collection(CONVERSATION_COLLECTION)
    .where(
      "members",
      "array-contains",
      portal.collection(USER_COLLECTION).doc(userId)
    )
    .limit(limit)
    .orderBy("updated", "desc");

  const subscribeToConversationList = () => {
    return eventChannel((emmiter: any) => {
      list.onSnapshot({ includeMetadataChanges: true }, snapshot => {
        const myConversations = snapshot.docs.map(conversationToState);
        console.log(myConversations);
        if (snapshot.docChanges().length !== 0) {
          emmiter(myConversations);
        }
      });

      return () => list;
    });
  };

  const channel = yield call(subscribeToConversationList);
  try {
    while (true) {
      const list = yield take(channel);
      yield put({ type: "CONVERSATION_LIST_RECEIVED", list });
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

const messageToState = doc => ({
  id: doc.id,
  ...doc.data()
});

const contactToState = doc => ({
  id: doc.id,
  ...doc.data()
});

let unsub;

function* fetchConversation({ id }) {
  if (unsub) {
    unsub();
  }
  const portal = yield take("PORTAL");
  const history = yield portal
    .collection(CONVERSATION_COLLECTION)
    .doc(id)
    .collection(MESSAGE_COLLECTION)

    .limit(100)
    .orderBy("created");

  const subscribeToMessageList = () => {
    return eventChannel((emmiter: any) => {
      unsub = history.onSnapshot({ includeMetadataChanges: true }, snapshot => {
        const messages = snapshot.docs.map(messageToState);

        if (snapshot.docChanges().length !== 0) {
          emmiter(messages);
        }
      });

      return () => history;
    });
  };

  const channel = yield call(subscribeToMessageList);
  try {
    while (true) {
      const list = yield take(channel);
      yield put({ type: "CONVERSATION_RECEIVED", id, history: list });
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function createContact(collection, contactObject) {
  return collection.add({
    created: firebase.firestore.FieldValue.serverTimestamp(),
    ...contactObject
  });
}

const getWhoamiId = ({ whoami }) => whoami.id;

function* createConversation({
  members = [],
  labels,
  firstMessage = false,
  callback = false
}) {
  const portal = yield take("PORTAL");
  const memberRefs = yield Promise.all(
    members.map(obj =>
      createContact(portal.collection(CONTACT_COLLECTION), obj)
    )
  );
  const whoamiId = yield select(getWhoamiId);
  const memberList = [
    portal.collection(USER_COLLECTION).doc(whoamiId),
    ...memberRefs
  ];
  const membersSnapshot = yield buildMembersSnapshot(memberList);

  const newConversation = yield portal
    .collection(CONVERSATION_COLLECTION)
    .add({
      members: memberList,
      membersSnapshot,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
      labels: labels || {}
    })
    .then(docRef => docRef.get())
    .then(doc => conversationToState(doc))
    .catch(function(error) {
      console.error("Error starting conversation: ", error);
    });
  yield put({
    type: "CONVERSATION_UPSERTED",
    conversation: newConversation
  });
  if (firstMessage) {
    yield put({
      type: "MESSAGE_POST",
      conversationId: newConversation.id,
      content: firstMessage
    });
  }
  if (callback) {
    callback(newConversation.id);
  }
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function* messageSend({ conversationId, content }) {
  const portal = yield take("PORTAL");

  const docRef = portal.collection(CONVERSATION_COLLECTION).doc(conversationId);
  const whoamiId = yield select(getWhoamiId);

  const authorRef = portal.collection(USER_COLLECTION).doc(whoamiId);

  const { members } = yield docRef.get().then(doc => doc.data());
  const membersSnapshot = yield buildMembersSnapshot(members);

  const recipients = yield Promise.all(
    members
      .filter(m => m.parent.id === CONTACT_COLLECTION)
      .map(ref => ref.get().then(contactToState))
  )
    .then(recipientObjects => {
      return recipientObjects.map(r => r.mobile).flat();
    })
    .then(numbers => Promise.all(numbers.map(nr=>new Promise((y)=>{
      lookup(nr).then(normalized=>{
        y(normalized?{
          nr:normalized,
          valid:true
        }:{
          nr,
          valid:false
        })
      })
    }))));


  let status = {};
  for (const {nr,valid} of recipients) {
    status[nr] = {
      datetime: firebase.firestore.FieldValue.serverTimestamp(),
      status: valid?"sent":"invalid_phone_nr"
    };
  }

  const message = yield docRef

    .collection(MESSAGE_COLLECTION)
    .add({
      content,
      author: authorRef,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      reference: conversationId + ":" + makeid(6),
      status
    })
    .then(docRef => docRef.get())
    .then(doc => messageToState(doc))
    .catch(function(error) {
      console.error("Error adding document: ", error);
    });

  //yield put({ type: "CONVERSATION_APPEND", id: conversationId, message });

  yield docRef
    .set(
      {
        updated: message.created,
        last: {
          content: message.content.substring(0, 100),
          author: authorRef
        },
        membersSnapshot
      },
      { merge: true }
    )
    .then(() => docRef.get())
    .then(doc => conversationToState(doc));

  /* yield put({
    type: "CONVERSATION_UPSERTED",
    id: conversationId,
    conversation: ConversationUpdated
  });*/

  sendNotifications(message, recipients.filter(({valid})=>valid).map(({nr})=>nr));
}


function sendNotifications(message, recipients) {
  if (recipients.length) {
    notify({
      recipients,
      body: message.content,
      reference: message.reference
    });
  }
}

function buildMembersSnapshot(members) {
  return Promise.all(
    members
      .filter(memberRef => memberRef.get)
      .map(memberRef => memberRef.get().then(contactToState))
  );
}

function* actionWatcher() {
  yield takeEvery("PORTAL_FETCH", fetchPortal);
  yield takeEvery("WHOAMI", fetchWhoami);
  yield takeEvery("CONVERSATION_LIST_FETCH", fetchConversationList);
  yield takeEvery("CONVERSATION_FETCH", fetchConversation);
  yield takeEvery("CONVERSATION_START", createConversation);
  yield takeEvery("MESSAGE_POST", messageSend);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
