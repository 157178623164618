import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#08C" },
    secondary: { main: '#404957' },
    //https://mycolor.space/?hex=%232C85CA&sub=1
  },
});

export default theme