import React from "react";
import { connect } from "react-redux";

import { Conversation, Input } from "components";

import { withStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { CircularProgress, Chip } from "@material-ui/core";

import { MAX_SMS_LENGTH } from "Constants";

const styles = theme => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	bottom: {
		position: "sticky",
		bottom: 0,
		left: 0,
		width: "100%"
	}
});

class Inbox extends React.Component {
	state = {
		message: "",
		history: []
	};

	sendMessage = content => {
		const { current } = this.props;
		this.props.dispatch({
			type: "MESSAGE_POST",
			conversationId: current,
			content
		});
	};

	setMsg = e => {
		this.setState({
			message: e.target.value
		});
	};

	submit = e => {
		e.preventDefault();
		const msg = this.state.message.trim();
		if (msg) {
			if (msg.length > MAX_SMS_LENGTH) {
				this.props.dispatch({
					type: "TOAST",
					message: `Maximum SMS length is ${MAX_SMS_LENGTH} characters`,
					variant: "error"
				});
			} else {
				this.sendMessage(msg);
				this.setState({
					message: ""
				});
			}
		}
	};
	loadConversation(id) {
		this.props.dispatch({
			type: "CONVERSATION_FETCH",
			id
		});
	}
	componentDidMount() {
		if (this.props.current) {
			this.loadConversation(this.props.current);
		}
	}
	componentDidUpdate({ current: prevConversationId }) {
		const { current } = this.props;
		if (current && prevConversationId !== current) {
			this.loadConversation(current);
		}
	}


	render() {
		const { history, current, classes, whoami } = this.props;

		if (history===false) {
			return (
				<div
					style={{
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					{history === false ? (
						<Chip label="Please select a chat to start messaging" />
					) : (
						<CircularProgress className={classes.progress} />
					)}
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className={classes.content}>
					<Conversation
						current={current}
						messages={history||[]}
						whoami={whoami}
					/>
				</div>
				<div className={classes.bottom}>
					<Input
						inputProps={{
							placeholder: "Write a message..."
						}}
						value={this.state.message}
						onSubmit={this.submit}
						onInput={this.setMsg}
						SubmitIcon={SendIcon}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default connect(({ conversation, whoami }) => ({
	whoami,
	current: conversation.current,
	history: !conversation.current
		? false
		: conversation.conversations[conversation.current]
}))(withStyles(styles)(Inbox));
