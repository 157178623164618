import React from "react";

import {
  Paper,
  Typography,
  withStyles,
  Tooltip,
  Popper,
  Fade
} from "@material-ui/core";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { OkIcon,  ExclamationIcon, BubbleIcon, EllipsisIcon } from "icons";

import moment from "momentLocale";

const statusIcons={
  scheduled:EllipsisIcon,
  sent:EllipsisIcon,
  buffered:EllipsisIcon,
  delivered:OkIcon,
  delivery_failed:ExclamationIcon,
  expired:ExclamationIcon,
  invalid_phone_nr:ExclamationIcon,
}


const styles = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    display: "flex",
    float: "left",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "--captionColor": "var(--dull)",
    maxWidth: "40em"
  },
  msgContainer: {
    display: "flex"
  },
  isMeContainer: {
    [theme.breakpoints.down("md")]: {
      float: "right",
      flexDirection: "row-reverse"
    }
  },
  isMe: {
    background: "#EFFDDE",
    "--captionColor": "var(--green)"
  },
  caption: {
    color: "var(--captionColor)",
    marginLeft: theme.spacing(1.5),
    transform: `translatey(${theme.spacing(0.75)}px)`
  },
  popper: {
    padding: theme.spacing(2)
  }
});

class Message extends React.Component {
  render() {
    moment.locale("nl");

    const {
      content,
      classes,
      created,
      id,
      author,
      whoami,
      status
    } = this.props;
    const isMe = author.id === whoami.id;
    return (
      <div
        className={
          classes.msgContainer + (isMe ? " " + classes.isMeContainer : "")
        }
      >
        <Paper
          data-id={id}
          className={classes.root + " " + (isMe ? classes.isMe : "")}
        >
          <Typography component="p" style={{ flexGrow: 1 }}>
            {content}
          </Typography>
          <Tooltip
            title={moment(created ? created.toDate() : new Date()).format("LLLL")}
          >
            <Typography variant="caption" className={classes.caption}>
              {moment(created ? created.toDate() : new Date()).format("LT")}
            </Typography>
          </Tooltip>
        </Paper>
        {status ? (
          <div style={{display:"flex",margin:"0 5px"}}>
            {Object.keys(status).map(recipient =>
              this.statusIcon(recipient, status[recipient])
            )}
          </div>
        ) : null}
      </div>
    );
  }

  statusIcon(recipient, { datetime, status, mccmnc, ported }) {
    const { classes } = this.props;
    const Status=statusIcons[status]||ExclamationIcon;
    return (
      <PopupState key={recipient} variant="popper">
        {popupState =>
        (
            <div>
            <Tooltip title={status}>
              <div style={{ cursor: "pointer", width: 34, height: 34,position:"relative",margin:"0 2px" }} {...bindToggle(popupState)}>
                
                  <BubbleIcon style={{ width: "100%", height: "100%" }} />
                  <Status style={{"width":"66%","height":"66%","position":"absolute","top":"12%","left":"17%"}} />
                
               
              </div>
 </Tooltip>
              <Popper
                {...bindPopper(popupState)}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.popper}>
                      <Typography>
                        {(() => {
                          let title = (
                            <React.Fragment>
                              <b>{status}</b>
                              {datetime?" on " + moment(datetime.toDate()).format("LLL"):""}
                              <br />
                              <b>recipient</b>
                              {": " + recipient}
                              <br />
                              <b>mccmnc</b>
                              {": " + mccmnc}
                              <br />
                              <b>ported</b>
                              {": " + (typeof ported ==="undefined"?ported:(ported ? "yes" : "no"))}
                            </React.Fragment>
                          );
                          return title;
                        })()}
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          )
        }
      </PopupState>
    );
  }
}

export default withStyles(styles)(Message);
