const defaultState = {};

const notification = (state = defaultState, action) => {
  switch (action.type) {
    case "CONVERSATION_UPSERTED":
      if (!action.id) {
        return {
          ...state,
          toast: {
            open: true,
            variant: "success",
            message: "SMS notification sent",
            goto: (window.location.pathname.match(/^\/admin/)?"/admin/conversations":"")+"/chat/" + action.conversation.id
          }
        };
      }
      return state;
    case "TOAST":
      return {
        ...state,
        toast: {
          open: true,
          variant: action.variant || "info",
          message: action.message
        }
      };
    case "TOAST_CLOSE":
      return {
        ...state,
        toast: {
          ...state.toast,
          open: false
        }
      };
    default:
      return state;
  }
};

export default notification;
