import moment from 'moment'
import 'moment/locale/en-gb';

//import englishLocale from "moment/locale/en-gb";
//const globalMoment=moment()
moment.locale("en-gb")


export default moment


