import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";

//import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import createRootReducer from "./reducers/_rootReducer";
import rootSaga from "./sagas";

const persistConfig = {
  key: "root",
  whitelist: [],
  storage
};

export const history = createBrowserHistory();

//const persistedReducer = persistReducer(persistConfig, rootReducer);
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const createEffectMiddleware = () => {
  let portalDocument;

  return next => effect => {
    if (effect.payload) {
      if (effect.payload.action) {
        if (effect.payload.action.type === "PORTAL_RECEIVED") {
          portalDocument = effect.payload.action.portal;
        }
      }
      if(effect.payload.pattern==='PORTAL'){
        return next(portalDocument)
      }
    }
 

    return next(effect);
  };
};

export default (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware({
    effectMiddlewares: [createEffectMiddleware()]
  });

  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};
