import React from "react";

import { Chip, Grid } from "@material-ui/core";

import { Message } from "components";

import { withStyles } from "@material-ui/core/styles";

import moment from "momentLocale";

const styles = theme => ({
	dateChip: {
		margin: theme.spacing(1),

		pointerEvents: "all",
		cursor: "n-resize"

		//color: "white",
		//background: "rgba(38, 54, 37, 0.54)",
		//fontWeight: "500"
	},
	screenReaderInv: {
		color: "transparent",
		fontSize: 0,
		width: 1,
		height: 1,
		overflow: "hidden",
		display: "block"
	},
	dayLinkCont: {
		display: "flex",
		justifyContent: "center",
		position: "sticky",
		top: "0",
		pointerEvents: "none"
	},
	dayLink: { textDecoration: "none" }
});

class Conversation extends React.Component {
	scrollToBottom = () => {
		document
			.getElementById("chat-body-container")
			.scrollTo(0, 100000000000000);
	};

	componentDidMount() {
		this.scrollToBottom();
	}

	componentDidUpdate({ messages: prevMessages, current: prevCurrent }) {
		const { messages, current } = this.props;
		if (
			prevCurrent !== current ||
			messages.length !== prevMessages.length
		) {
			this.scrollToBottom();
		}
	}

	groupByDay(messages) {
		const replace = moment().format("Y");
		let refs = {};
		let order = [];
		for (const message of messages) {
			const dayText = moment(message.created?message.created.toDate():new Date())
				.format("LL")
				.replace(replace, "  ")
				.replace(/^\W{1,}|\W{1,}$|\W{3,3}/, "");

			if (!refs[dayText]) {
				refs[dayText] = [];
				order.push(dayText);
			}
			refs[dayText].push(message);
		}
		return { refs, order };
	}

	render() {
		const { messages, classes, whoami } = this.props;

		const { refs, order } = this.groupByDay(messages);

		return (
			<Grid container spacing={1} direction="column">
				{order.map(dayText => {
					const dayTextMachine = dayText
						.toLowerCase()
						.replace(/ /g, "-");
					return (
						<Grid key={dayText} item>
							<Grid container spacing={1} direction="column">
								<a
									href={"/"}
									id={dayTextMachine}
									className={classes.screenReaderInv}
								>
									{dayTextMachine}
								</a>
								<Grid item className={classes.dayLinkCont}>
									<a
										href={"#" + dayTextMachine}
										className={classes.dayLink}
									>
										<Chip
											size="small"
											className={classes.dateChip}
											label={dayText}
										/>
									</a>
								</Grid>

								{refs[dayText].map(msg => (
									<Grid key={msg.id} item>
										<Message {...msg} whoami={whoami} />
									</Grid>
								))}
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		);
	}
}

export default withStyles(styles)(Conversation);
